/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';
import MoneyFields from '../fragments/money/money-fields';

export const OperationBasicFields = gql`
  fragment OperationBasicFields on OperationType {
    id
    totalAmount {
      ...MoneyFields
    }
    commission {
      ...MoneyFields
    }
  }
  ${MoneyFields}
`;
